// Global styles

html {
  box-sizing: border-box;
}

* {
  &,
  &::before,
  &::after {
    box-sizing: inherit;
  }
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: $gray-haze;
}

.rocketfuel-root,
.rocketfuel-app {
  height: 100%;

  input[type="number"] {
    -moz-appearance: textfield; // For Firefox
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    margin: 0;
    -webkit-appearance: none; // For Chrome and Safari
  }

  input {
    -webkit-appearance: none; // For Chrome and Safari
  }
}

.bsd-modal {
  width: 350px;

  @include responsive($width-small) {
    width: 670px;
  }

  @include responsive($width-medium) {
    width: 770px;
  }
}
