.rocketfuel-header {
  display: flex;
  height: 70px;
  background-color: rgb(17, 47, 100);

  &-main {
    height: 20px;
    background-color: #0e2650;
  }

  & a {
    align-items: center;
    min-width: 80px;
    height: 100%;
    padding: 15px 0 0 15px;
  }

  &-logo {
    min-width: 120px;
  }
}
