// Most variables and mixins should come from bsd-ui-stylekit
// https://github.comcast.com/BSD-Digital/bsd-ui-stylekit/

// Add Lato Light font face
@include lato("light", 300);

@mixin checkbox-minus-icon {
  .bsd-checkbox-label {
    &::after {
      top: 29px;
      width: 20px;
      height: 3px;
      background-color: $black;
      visibility: visible;
    }

    &:hover,
    &:focus {
      &::after {
        background-image: none;
      }
    }
  }
}

@mixin type-large-digits {
  color: $black;
  font-size: $heading-3;
  font-weight: $font-weight-light;
  letter-spacing: -2px;
}

@mixin light-text {
  font-size: $heading-5;
  font-weight: $font-weight-light;
  line-height: 1.2;

  @include responsive($width-medium) {
    font-size: $heading-4;
  }
}

@mixin abbreviate-with-ellipsis {
  // Truncate text with ellipsis if it flows to the next line
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;
  -webkit-appearance: none;
  -moz-appearance: none;
}

/*
* the following two mixins apply to the three sections of
* schedule review cards which have similar patterns,
* but are kept separate for re-use.
*/

@mixin review-schedules-selections {
  display: flex;
  position: relative;
  flex-direction: column;

  &--with-gradient {
    &::after {
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // stylelint-disable max-line-length
      /* rgba needed for gradient */
      background: linear-gradient(rgb(255 255 255 / 0%) 0%, rgb(255 255 255 / 100%));
      // stylelint-enable max-line-length
      content: "";
    }
  }
}

@mixin review-schedules-selected-item {
  margin-bottom: base-spacing();
}

@mixin tag($color, $background) {
  border: none;
  border-radius: base-spacing(0.25);
  background-color: $background;
  color: $color;
}

@mixin tooltip {
  position: relative;

  @include responsive($width-medium) {
    margin-bottom: 0;
  }

  .bsd-tooltip {
    width: auto;
    transform: translate(-140px, 40px);
  }

  .bsd-icon-btn--with-label {
    align-items: flex-start;

    // To make icon align with the text
    .bsd-icon {
      margin-top: 3px;
    }
  }
}

@mixin tooltip-close-button($size: 15px) {
  .bve-tooltip-button-tooltip-close {
    width: 20px;
    height: 20px;
    margin: base-spacing(0.5) base-spacing(0.5) 0 auto;

    .bsd-icon {
      width: $size;
      min-width: $size;
      height: $size;
    }
  }
}

/*
* Mixin for handling animations
*/
@mixin animate($name, $duration, $fill-mode, $timing-function, $direction: normal) {
  animation-name: $name;
  animation-duration: $duration;
  animation-fill-mode: $fill-mode;
  animation-timing-function: $timing-function;
  animation-direction: $direction;
}
